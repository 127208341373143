// const baseUrl = `${process.env.REACT_APP_BASE_URL}/api/v1`;
const baseUrl = "https://yogydodev.azurewebsites.net/api/v1"
// const baseUrl ="http://localhost:3001/api/v1"

const yogydoService = `${baseUrl}/yogydo`


//google routes
export const AUTH_URL =  `${yogydoService}/auth/google`;
export const GOOGLE_SYNC = `${yogydoService}/documents/google-drive/sync`;
export const USER_DETAILS =  `${yogydoService}/auth/user`;
export const DRIVE_FILES =`${yogydoService}/documents/google-drive/files`;
export const LOGOUT = `${yogydoService}/auth/logout`
//chat-gpt integration
export const CHAT_GPT_INTEGRATION = `${yogydoService}/llm/search`;
export const GET_CHAT_LIST = `${yogydoService}/chats`;
export const GET_CHATMESSAGES_LIST = (chatId) => `${yogydoService}/chats/${chatId}/messages`;
export const CREATE_CHATMESSAGES_LIST = (chatId) => `${yogydoService}/chats/${chatId}/messages`;
export const CHAT_MESSAGE_RATING_API = (msgId) => `${yogydoService}/messages/${msgId}/rate`;
export const CREATE_CHAT = `${yogydoService}/chats`;
export const CREATE_GUEST_CHAT = `${yogydoService}/chats/guest`;
export const UPDATE_CHAT = (chatId) => `${yogydoService}/chats/${chatId}`;

//user routes
export const LOGGED_IN_USER = `${yogydoService}/users`

export const googleFolder = "application/vnd.google-apps.folder"    