import React, { useEffect, useState } from "react";
import { TextField, colors, makeStyles } from "@material-ui/core";
import { getEllipsisText } from "../../helpers";
import ReactEditIcon from "../../assets/images/editIcon.png";
import { useNavigate } from "react-router";



const useStyles = makeStyles((theme) => ({
    root: {

    },
    close: {
        '& button': {
            background: 'none',
            color: 'black',
            padding: 0
        }
    },
    recentList: {
        overflow: 'auto',
        transition: 'width 0.5s ease',
        backgroundColor: '#f9f9f9',
        padding: '0px',
        height: '100vh',//'calc(100vh - 60px)',
        '&::-webkit-scrollbar': {
            width: 4
        },
        '&::-webkit-scrollbar-track ': {
            background: "#f1f1f1"
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#cccccc'
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#a3a3a3'
        }
    },
    newChat: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px 10px',
        cursor: 'pointer',
        '& p': {
            color: '#00008B',
            margin: 0,
            fontWeight: 'bold',
            fontSize: '18px',
            '& img': {
                width: 24,
                height: 24,
                opacity: 0.8
            }
        }
    }
}));


const RecentMessages = ({ chatLists, setRecentChatId, setNewChat, recentChatId, setMessages, userDetails }) => {
    const classes = useStyles();
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(true);
    const toggleSidebar = () => setIsOpen(!isOpen);


    useEffect(() => {
        if (!recentChatId) {
            setRecentChatId(chatLists[0]?._id);
        }
    }, [chatLists]);
    
    const handleNewChatClick = () => {
        if (userDetails) {
            setNewChat(true);
            setRecentChatId(null);
        } 
        else{
            setMessages([])
            setRecentChatId(null);
        }
    };

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div className={classes.recentList} style={{ width: isOpen ? '250px' : '0' }}>
                    {isOpen && (
                        <nav style={{ padding: 10 }}>
                            <div className={classes.newChat} onClick={handleNewChatClick}>
                                <p style={{ cursor: 'pointer' }}>Yogydo </p>
                                <p>
                                    <img src={ReactEditIcon} alt="Edit" />
                                </p>
                            </div>
                            <div>
                                {chatLists.map((chat, i) => (
                                    <div key={i}>
                                        <p
                                            onClick={() => {
                                                setRecentChatId(chat._id);
                                                navigate(`/chatbot/${chat._id}`);
                                            }}
                                            className={recentChatId === chat._id ? 'recentChatsSelected' : 'recentChats'}
                                        >
                                            {getEllipsisText(chat?.title, 40)}
                                        </p>
                                    </div>
                                ))}
                            </div>
                        </nav>
                    )}
                </div>
                <div className={classes.close}>
                    <button onClick={toggleSidebar}>
                        {isOpen ? '<' : '>'}
                    </button>
                </div>
            </div>
        </div >
    );
};


export default RecentMessages;