import React, { useEffect, useState } from 'react';
import './App.css';
import {
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from './Components/home/Home';
import Login from './Components/login/Login';
import ChatBot from './Components/ChatBot';
import { LOGGED_IN_USER } from './api';
import axios from 'axios';

function App({setUserDetails ,userDetails}) {

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<ChatBot userDetails={userDetails}/>} />
        <Route path="/home" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route  path="*" element={<Navigate to="/" />} />  
        <Route   exact path='/chatbot/:chatId' element={<ChatBot userDetails = {userDetails}/>}/>
      </Routes>
    </div>
  )

}


export default App;
