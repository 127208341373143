import React, { useEffect, useRef, useState } from "react";
import { Grid, TextField, makeStyles, Button, colors } from "@material-ui/core";
import { SiGoogledrive } from 'react-icons/si';
import axios from 'axios';
import './ChatBot.css';
import { GET_CHATMESSAGES_LIST, CREATE_CHATMESSAGES_LIST, CHAT_MESSAGE_RATING_API, CREATE_CHAT, LOGOUT,  CREATE_GUEST_CHAT, UPDATE_CHAT ,GET_CHAT_LIST} from "../../api";
import { markdown } from "./MarkDown";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { typeOfChatRoles } from ".";
import ReactStars from "react-rating-stars-component";
import { useNavigate } from "react-router-dom";
import {
    useParams
} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        position: 'relative',
    },
    root1: {
        // width: '100% !important',
        // height: 'calc(100vh - 116px)',
        overflowY: 'auto',
        padding: '0px 15%',
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        '& .send_btn': {
            backgroundColor: theme.palette.primary.main,
        },
        '& .heading': {
            color: '#5C2A7D',
            textAlign: 'center',
            fontSize: '23px',
            margin: '5px 0px'
        }
    },
    searchContainer: {
        display: 'flex',
        borderRadius: '22px', /* Adjust as needed */
        boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.1)', /* Creates the shadow effect */
        overflow: 'hidden',
        border: '1px solid #d4d4d4',
        width: '100%',

    },
    searchInput: {
        border: 'none',
        outline: 'none',
        padding: '10px 20px',
        fontSize: 16, /* Adjust as needed */
    },
    searchButton: {
        background: '#e8e8e8',
        border: 'none',
        cursor: 'pointer',
        padding: '4px 6px',
        margin: 8,
        borderRadius: 10,
    },
    searchImg: {
        width: 20, /* Adjust as needed */
        height: 20, /* Adjust as needed */
    },
    query: {
        border: '1px solid #d4d4d4',
        borderRadius: 15,
        padding: '10px 15px !important',
        color: 'dimgrey',
        cursor: 'pointer',
        fontSize: 11,
        fontWeight: 'bold',
        minHeight: 33
    },
    autocompleteRoot: {
        borderRadius: '6px',
        padding: '0px !important',
        width: 130
    },
    lengthView: {
        display: 'block',
        color: ' rgba(0, 0, 0, 0.54)',
        margin: 0,
        fontSize: '0.75rem',
        marginTop: '1px',
        textAlign: 'right',
        fontWeight: '400',
        lineHeight: '1.66',
        letterSpacing: '0.03333em'
    },
    loginButton: {
        position: 'absolute',
        top:40,
        right:30,
        padding: '10px 30px',
        borderRadius: 10,
        cursor: 'pointer',
    },
    googleDriveButton: {
        position: 'absolute',
        top:100,
        right:30,
        padding: '10px 20px',
        borderRadius:10,
        cursor: 'pointer',
    },
    iconStyle: {
        color: '#4285F4',
    },
    logoutButton: {
        position: 'absolute',
        top:40,
        right:30,
        padding: '10px 20px',
        borderRadius: 10,
        cursor: 'pointer',
    },
}));

const querysList = [
    {
        id: 1,
        question: 'What are the reasons for rejection of claim?'
    },
    {
        id: 2,
        question: 'How to cross sell a Insurance product?'
    },
    {
        id: 3,
        question: 'In how may days a customer claim will be settled?'
    },
    {
        id: 4,
        question: 'What are the document needed to purchase Term insurance policies?'
    }
]

const ChatList = ({ messages, setMessages,userDetails,recentChatId, typeOfRole, setTypeOfRole, newChat, setNewChat, setRecentChatId ,setChatLists ,setUserDetails}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [input, setInput] = useState('');
    const myMessage = useRef(null);
    const [isLoading, setLoading] = useState(true);
    const { chatId } = useParams();
    const [chatUpdated, setChatUpdated] = useState(false);

    useEffect(() => {
        myMessage.current.scrollIntoView({ block: 'end', behavior: 'smooth', inline: "nearest" });
    }, [messages]);


    // useEffect(() => {
    //     getPersonDetails();
    // }, []);

//  useEffect(() => {
//     getRecentChatMessagesList(recentChatId);
//     }, []);


    useEffect(() => {
        if(newChat){
            OnCreateChat();
            setMessages([]);
        }
    }, [newChat]);

    useEffect(() => {
        if (recentChatId && !newChat) {
            getRecentChatMessagesList(recentChatId);
        }
    }, [recentChatId, typeOfRole?.id])

    
    const getRecentChatsList = () => {
        setLoading(true);
        const config = {
            params: { limit: 5000, page: 1 },
            withCredentials: true
        };
        axios.get(GET_CHAT_LIST, config)
            .then(response => {
                setChatLists(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching chat lists:', error);
                setLoading(false);
            });
    };
    const OnCreateChat = async () => {
        try {
            setLoading(true);
            const config = userDetails ? { withCredentials: true } : {};
            const response = await axios.post(CREATE_CHAT, {}, config);
            const result = response.data;
            setRecentChatId(result.id);
            setNewChat(null);
            navigate(`/chatbot/${result.id}`);
             getRecentChatsList()
        } catch (error) {
            console.error('Error creating chat:', error);
        } finally {
            setLoading(false);
        }
    };
    // ---------chat creation ---------




    // ----chat messageLists-----------
    const getRecentChatMessagesList = async (chatId) => {
        try {
            setLoading(true);
            const config = {
                params: { limit: 5000, page: 1, type: typeOfRole?.id },
                withCredentials: true
            };
            const response = await axios.get(GET_CHATMESSAGES_LIST(chatId), config);
            setMessages(response.data);
        } catch (error) {
            console.error('Error fetching chat messages:', error);
        } finally {
            setLoading(false);
        }
    };

    const onQueryClick = async (query) => {
        loadingMessage(query);
        try {
            const config = userDetails ? { withCredentials: true } : {};
            await axios.post(CREATE_CHATMESSAGES_LIST(recentChatId), {
                chatId: recentChatId,
                message: query,
                type: typeOfRole?.id
            }, config);
            getRecentChatMessagesList(recentChatId);
            await updateChat(query);
            getRecentChatsList()
        } catch (error) {
            setLoading(false);
            console.error("Error creating chat message:", error);
        }
    }


    //"Thinking..."
    const ThinkingLoader = () => {
        const classes = useStyles();
        return (
            <div className="loader"></div>
        )
    }
    const updateChat = async (body) => {
        try {
            const trimmedBody = body.split(' ').slice(0, 4).join(' ');
            await axios.put(UPDATE_CHAT(recentChatId), {
                title: trimmedBody + '...',
                type: typeOfRole?.id
            },
                { withCredentials: true }
            )
        } catch (error) {
            setLoading(false);
            console.error("Error updating chat message:", error);
        }
    }

    

    const loadingMessage = (input, type, respose) => {
        // console.log("input",input)
        const dubMessages = [
            { _id: "123434", rating: 0, message: input, role: 'USER', title: "", created_at: '' },
            { _id: "1234345", rating: 0, role: 'AGENT', title: "", message: 'Thinking...', loading: true, created_at: '' }
        ]
        if (type == "notLogin") {
            dubMessages[1].message = respose;
            dubMessages[1].loading = false;
        }
        setMessages([...messages, ...dubMessages]);
        setInput('');
    }

    const sendMessage = async (e) => {
        e.preventDefault();
        setInput('');
        if (input.trim() !== '') {
            if (recentChatId) {
                loadingMessage(input);
                try {
                    await axios.post(CREATE_CHATMESSAGES_LIST(recentChatId), {
                        chatId: recentChatId,
                        message: input,
                        type: typeOfRole?.id
                    }, {
                        withCredentials: userDetails ? true : false
                    });
                    getRecentChatMessagesList(recentChatId);
                    if (!chatUpdated) {
                        await updateChat(input);
                        setChatUpdated(true);
                    }
                    getRecentChatsList()
                } catch (error) {
                    setLoading(false);
                    console.error("Error creating chat message:", error);
                }
            }
        }
    };

    const sendGUestMessage = async (e) => {
        e.preventDefault();
        setInput('');
        if (input.trim() !== '') {
            loadingMessage(input);
            try {
                const response = await axios.post(CREATE_GUEST_CHAT, {
                    message: input,
                });
                loadingMessage(input, "notLogin", response.data);
            } catch (error) {
                setLoading(false);
                console.error("Error creating chat message:", error);
            }
        }
    };

    const guestUserMessage = async (query) => {
        loadingMessage(query);
        try {
            const response = await axios.post(CREATE_GUEST_CHAT, {
                message: query,
            })  
            loadingMessage(query, "notLogin", response.data);
        } catch (error) {
            setLoading(false);
            console.error("Error creating chat message:", error);
        }
    }

    //rate a message
    const messageRating = (msg, value) => {
        let copy_messages = JSON.parse(JSON.stringify(messages))
        const msgIndex = copy_messages.findIndex(ms => ms._id === msg._id)
        if (value) {
            copy_messages[msgIndex].rating = value;
            setMessages([...copy_messages]);
            updateMessages(copy_messages[msgIndex]._id, value)
        }
    };
    const updateMessages = async (msgId, value) => {
        try {
            await axios.put(CHAT_MESSAGE_RATING_API(msgId), {
                msgId: msgId,
                rating: value
            }, { withCredentials: true });
        } catch (error) {
            console.error('Error rating message:', error);
        }
    };

    // const handleDelete = async (chatId) => {
    //     try {
    //         await axios.delete(DELETE_CHAT(chatId), {
    //             chatId: chatId
    //         },
    //             { withCredentials: true }
    //         )
    //     } catch (error) {
    //         console.error('Error deleting message:', error);
    //     }
    // }

    const handleLogout = async () => {
        try {
            await axios.get(LOGOUT, { withCredentials: true });
            setChatLists([]);
            setMessages([]);
            navigate('/');
            setUserDetails(false);
        } catch (error) {
            console.error('Error during logout:', error);
        }
    };

    const handleLoginClick = ()=>{
        navigate('/login')
    }

    const hadleGooleDrive = ()=>{
        navigate('/home')
    }

    const availableRoles = userDetails ? typeOfChatRoles : typeOfChatRoles.filter(role => role.id === 'SEARCH');
    return (
        <div id="ChatBot-data" className={classes.root}>
            {!userDetails && (
                <Button className={classes.loginButton} variant="contained" color="primary" onClick={handleLoginClick}>
                    Login
                </Button>
            )}
            {userDetails &&(
                  <Button className={classes.logoutButton} variant = "contained" color = "secondary" onClick={handleLogout}>
                  Logout
              </Button>
            )}
            {userDetails && (
                 <Button className={classes.googleDriveButton} variant="contained" color="primary" onClick={hadleGooleDrive} startIcon={<SiGoogledrive className={classes.iconStyle} />}  >
                 Drive
             </Button>
            )}      
             {userDetails && (
            <div style={{ width: 120, position: 'absolute', top: 20 }}>
                <Autocomplete
                    id='role'
                    autoComplete
                    options={availableRoles}
                    disableClearable
                    size="small"
                    getOptionLabel={(option) => option.name}
                    value={typeOfRole}
                    renderInput={(params) => <TextField {...params} style={{ fontSize: '10px !important', padding: '0px !important' }} label="Select Role" variant="outlined" />}
                    onChange={(e, val) => setTypeOfRole(val)}
                    classes={classes.autocompleteRoot}
                    style={{ padding: '0px !important', }}
                />
            </div>
             )}
            <div className={classes.root1}>
                <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <div className="chat-interface">
                        <div className="messages" ref={myMessage}>
                            <div style={{ minHeight: 300 }}>
                                {messages.map((msg, msgIndex) =>
                                    <>
                                        <div key={`message_${msg._id}`} className="message">
                                            {
                                                msg.role === 'AGENT' ?
                                                    <>
                                            <div className="messageAnswer" style={msg?.loading ? { fontSize: 13, lineHeight: '8px' } : {}}>
                                                            {msg.loading ?
                                                    <ThinkingLoader />
                                                                :
                                                    <div>
                                                        <span dangerouslySetInnerHTML={{ __html: markdown(msg.message) }} />
                                                        <div style={{ float: 'right', marginTop: '-5px' }}>
                            <ReactStars
                                count={5}
                                                                classNames={classes.rating}
                                                                value={msg?.rating || 0}
                                                                onChange={(newRating) => messageRating(msg, newRating)}
                                                                size={20}
                                                                style={{ float: 'right', marginTop: '-8px' }}
                                activeColor="#ffd700"
                                                            />
                                                        </div>
                                                    </div>
                                                            }
                                            </div>
                                                    </>
                                                    :
                                            <div className="query">
                                                {msg.message}
                                            </div>
                                            }
                                    </div>
                                    </>
                                )}
                            </div>
                            {(messages?.length === 0) &&
                                // <div style={{ position: 'absolute', bottom: 70, left: 0, padding: '0px 15px' }}>
                                <div style={{ padding: 0 }}>
                                    <h3 style={{ textAlign: 'center' }}>How can I help you today? </h3>
                                    <Grid container spacing={1}>
                                        {querysList.map((el, i) =>
                                            <Grid key={i} item xs={6}>
                                                {
                                                    userDetails
                                                        ? (
                                                            <div className={classes.query} onClick={() => onQueryClick(el?.question)}>
                                                                {el.question}
                                                            </div>
                                                        )
                                                        : (
                                                            <div className={classes.query} onClick={() => guestUserMessage(el?.question)}>
                                                                {el.question}
                                                            </div>
                                                        )
                                                }
                                            </Grid>
                                        )}
                                    </Grid>
                                </div>
                            }
                        </div>
                    </div>
               {
                userDetails ?
                (
                    <div style={{ width: '100%' }} >
                        <form onSubmit={sendMessage} style={{ display: 'block' }}>
                            <div className={classes.searchContainer}>
                                <input
                                    type="text"
                                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                    className={classes.searchInput}
                                    placeholder="Ask AI Buddy..."
                                    inputProps={{ maxLength: 2000 }}
                                    max={2000}
                                    maxLength={2000}
                                />
                                <button className={classes.searchButton}>
                                    <ArrowUpwardIcon className={classes.searchImg} src="search-icon.png" alt="Search" />
                                </button>
                            </div>
                            <p className={classes.lengthView}> {`${input.length}/2000`} </p>
                        </form>
                    </div>
                ):(
                    <div style={{ width: '100%' }} >
                        <form onSubmit={sendGUestMessage} style={{ display: 'block' }}>
                            <div className={classes.searchContainer}>
                                <input
                                    type="text"
                    value={input}
                                    onChange={(e) => setInput(e.target.value)}
                                    className={classes.searchInput}
                                    placeholder="Ask AI Buddy..."
                                    inputProps={{ maxLength: 2000 }}
                                    max={2000}
                                    maxLength={2000}
                                />
                                <button className={classes.searchButton}>
                                    <ArrowUpwardIcon className={classes.searchImg} src="search-icon.png" alt="Search" />
                                </button>
                            </div>
                            <p className={classes.lengthView}> {`${input.length}/2000`} </p>
            </form>
                    </div>
                )
               }
                    
                </div>
            </div>
        </div >
    );
};


export default ChatList;