import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styles from './styles.module.css';
import { GOOGLE_SYNC, USER_DETAILS, DRIVE_FILES, googleFolder } from '../../api';
import { useNavigate } from 'react-router-dom';
import ChatBot from '../../Components/ChatBot';
import ChatList from '../ChatBot/ChatList'


const Home = () => {
    const [userDetails, setUserDetails] = useState(null);
    const [driveFiles, setDriveFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [syncFiles, setSyncFiles] = useState([]);
    const [isSyncing, setIsSyncing] = useState(false);
    const [syncMessage, setSyncMessage] = useState('');
    const [openFolders, setOpenFolders] = useState({});
    const [items, setItems] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        getUserDetails();
    }, []);
    
    useEffect(() => {
        localStorage.setItem('userDetails', JSON.stringify(items));
      }, [items]);

    useEffect(() => {
        const storedUserDetails = localStorage.getItem('userDetails');
        if (storedUserDetails) {
            setUserDetails(JSON.parse(storedUserDetails));
        }
    }, []);

    const handleSync = async () => {
        if (isSyncing) return;
        setIsSyncing(true);
        setSyncMessage('Syncing...');
        try {
            const { data } = await axios.post(GOOGLE_SYNC, {}, {
                headers: {
                    Authorization: `${userDetails.accessToken}`
                },
                withCredentials: true
            });
            setSyncMessage('Syncing completed');
            setSyncFiles(data);
        } catch (error) {
            console.error('Error syncing files:', error);
            setSyncMessage('Error syncing files');
        } finally {
            setIsSyncing(false);
        }
    };
    

    const getUserDetails = async () => {
        try {
            const { data } = await axios.get(USER_DETAILS, { withCredentials: true });
            setUserDetails(data);
            await getDriveFiles();
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
    };

 
  const handleYogydo = async()=>{
    navigate('/')
  }

    const getDriveFiles = async () => {
        setIsLoading(true);
        try {
            const { data } = await axios.get(DRIVE_FILES, {
                withCredentials: true,
            });
            setDriveFiles(data);
        } catch (error) {
            console.error('Error fetching drive files:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const toggleFolder = (folderId) => {
        setOpenFolders(prevState => ({
            ...prevState,
            [folderId]: !prevState[folderId]
        }));
    };

    const renderFilesAndFolders = (items) => {
        return (
            <ul>
                {items.map((item, index) => (
                    <li key={index}>
                        {item.mimeType === googleFolder ? (
                            <>
                                <span onClick={() => toggleFolder(item.id)} style={{ cursor: 'pointer', color: 'blue' }}>
                                    {openFolders[item.id] ? '📂' : '📁'} {item.name}
                                </span>
                                {openFolders[item.id] && item.folderFiles && renderFilesAndFolders(item.folderFiles)}
                            </>
                        ) : (
                            <a href={item.url} target="_blank" rel="noopener noreferrer">
                                📄 {item.name}
                            </a>
                        )}
                    </li>
                ))}
            </ul>
        );
    };

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <h1 className={styles.heading}>Welcome to Yogydo</h1>
                <div className="driveFiles">
                    <h2 className={styles.colorar}>Drive Files</h2>
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        renderFilesAndFolders(driveFiles)
                    )}
                </div>
                <div className={styles.syncButtonContainer}>
                    <button className={styles.syncButton} onClick={handleSync} disabled={isSyncing}>{isSyncing ? 'Syncing...' : 'Sync'}</button>
                    {syncMessage && <p>{syncMessage}</p>}
                </div>
                <div >
                    <button className= {styles.backButton} onClick={handleYogydo}> yogydo</button>
                </div>
            </div>
        </div>
    );

    // return (
    //     <div className={styles.container}>
    //         <div className={styles.content}>
    //             <h1 className={styles.heading}>Welcome to Yogydo</h1>
    //             <p className={styles.heading}>Stay tuned and follow us for updates!</p>
    //             <div className="driveFiles">
    //                 <h2 className={styles.colorar}>Drive Files</h2>
    //                 {isLoading ? (
    //                     <p>Loading...</p>
    //                 ) : (
    //                     renderFilesAndFolders(driveFiles)
    //                 )}
    //             </div>
    //             <div className={styles.syncButtonContainer}>
    //                 <button className={styles.syncButton} onClick={handleSync} disabled={isSyncing}>{isSyncing ? 'Syncing...' : 'Sync'}</button>
    //                 {syncMessage && <p>{syncMessage}</p>}
    //             </div>
    //             <div className={styles.logoutButtonContainer}>
    //                 <button className={styles.logoutButton} onClick={handleLogout}>  
    //                     Logout  
    //                 </button>
    //             </div>
    //         </div>
    //     </div>
    // );
};




  // return (
  //     <div className="comingSoon">
  //         <div className="content">
  //             <h1>Launching Soon</h1>
  //             <p>Stay tuned and follow us for updates!</p>
  //             <div className="driveFiles">
  //                 <h2>Drive Files</h2>
  //                 {isLoading ? (
  //                     <p>Loading...</p>
  //                 ) : (
  //                     <ul>
  //                         {driveFiles.map((file, index) => (
  //                             <li key={index}>
  //                                 {file.mimeType === "application/vnd.google-apps.folder" ? (
  //                                     <>
  //                                         <span onClick={() => toggleFolder(file.id)} style={{cursor: 'pointer', color: 'blue'}}>
  //                                             {file.name}
  //                                         </span>
  //                                         {openFolders[file.id] && file.FOlderfiles && (
  //                                             <ul style={{ marginLeft: '20px' }}>
  //                                                 {file.FOlderfiles.map((subFile, subIndex) => (
  //                                                     <li key={subIndex}>
  //                                                         <a href={subFile.url} target="_blank" rel="noopener noreferrer">
  //                                                             {subFile.name}
  //                                                         </a>
  //                                                     </li>
  //                                                 ))}
  //                                             </ul>
  //                                         )}
  //                                     </>
  //                                 ) : (
  //                                     <a href={file.url} target="_blank" rel="noopener noreferrer">
  //                                         {file.name}
  //                                     </a>
  //                                 )}
  //                             </li>
  //                         ))}
  //                     </ul>
  //                 )}
  //             </div>
  //             <div className={styles.syncButtonContainer}>
  //                 <button className={styles.syncButton} onClick={handleSync} disabled={isSyncing}>{isSyncing ? 'Syncing...' : 'Sync'}</button>
  //                 {syncMessage && <p>{syncMessage}</p>}
  //             </div>
  //         </div>
  //     </div>
  // );

  // return (
  //     <div className="comingSoon">
  //         <div className="content">
  //             <h1>Launching Soon</h1>
  //             <p>Stay tuned and follow us for updates!</p>
  //             <div className="driveFiles">
  //                 <h2>Drive Files</h2>
  //                 {isLoading ? (
  //                     <p>Loading...</p>
  //                 ) : (
  //                     <ul>
  //                         {driveFiles.map((file, index) => (
  //                              <li key={index}>
  //                              <a href={file.url} target="_blank" rel="noopener noreferrer">
  //                                  {file.name}
  //                              </a>
  //                          </li>
  //                         ))}
  //                     </ul>
  //                 )}
  //             </div>
  //             <div className={styles.syncButtonContainer}>
  //                 <button className={styles.syncButton} onClick={handleSync} disabled = {isSyncing}>{isSyncing ? 'Syncing...' : 'Sync'}</button>
  //                 {syncMessage && <p>{syncMessage}</p>}
  //             </div>
  //         </div>
  //     </div>
  // );  


export default Home;