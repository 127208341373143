import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import './ChatBot.css';
import RecentMessages from "./RecentMessages";
import ChatList from "./ChatList";
import { useSnackbar } from "notistack";
import { GET_CHAT_LIST, LOGGED_IN_USER, } from "../../api";
import axios from 'axios';
import { useParams } from "react-router-dom";

export const typeOfChatRoles = [{ name: 'Search', id: 'SEARCH' }, { name: 'Agent', id: 'AGENT' }];

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        display: 'flex',
    }
}));

const ChatBot = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();
    const [chatLists, setChatLists] = useState([]);
    const [recentChatId, setRecentChatId] = useState(null);
    const [newChat, setNewChat] = useState(null);
    const [typeOfRole, setTypeOfRole] = useState(typeOfChatRoles[0]);
    const { chatId } = useParams();
    const [isLoading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState(null);
    const [messages, setMessages] = useState([]);


    useEffect(() => {
        if (chatId) {
            setRecentChatId(chatId);
        }
    }, [chatId]);

   
    useEffect(() => {
        getUserDetails();
    }, []);

    useEffect(() => {
        if (userDetails) {
            getRecentChatsList();
        }
    }, [userDetails]);

   


    const getRecentChatsList = () => {
        setLoading(true);
        const config = {
            params: { limit: 5000, page: 1 },
            withCredentials: true
        };
        axios.get(GET_CHAT_LIST, config)
            .then(response => {
                setChatLists(response.data);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching chat lists:', error);
                setLoading(false);
            });
    };

    const getUserDetails = async () => {
        try {
            await axios.get(LOGGED_IN_USER, {
                withCredentials: true,
            }).then(response => {
              setUserDetails(response.data[0].active)
            })
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
      }

    return (
        <div className={classes.root}>
            <div style={{ display: 'flex', width: '100%' }}>
                <RecentMessages messages = {messages} setMessages = {setMessages} chatLists={chatLists} setRecentChatId={setRecentChatId} setTypeOfRole={setTypeOfRole} typeOfRole={typeOfRole} setNewChat={setNewChat} recentChatId={recentChatId}  userDetails={userDetails} setUserDetails = {setUserDetails}/>
                <ChatList userDetails={userDetails} recentChatId={recentChatId} setTypeOfRole={setTypeOfRole} typeOfRole={typeOfRole} newChat={newChat} setNewChat={setNewChat} setRecentChatId={setRecentChatId} setChatList={setChatLists} setUserDetails = {setUserDetails}  messages = {messages} setMessages = {setMessages} setChatLists ={setChatLists} />
            </div>
        </div>
    );
};

export default ChatBot;
