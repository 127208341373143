import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { AUTH_URL } from '../../api';

function Login() {

  const handleLogin = () => {
    console.log("Login",'');
    window.location.href = `${AUTH_URL}`;
  };
  
  return (
    <div className={styles.container}>
          <div className={styles.form_container}>
        <div className={styles.right}>
            <button className={styles.google_btn} onClick={handleLogin}>
                <img src="./images/google.png" alt="google icon" />
                <span>Sign in with Google</span>
            </button>
        </div>
        </div>
    </div>
);
}

export default Login;
